import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { environment } from '../../environments/environment';
import {
  AbstractSecurityStorage,
  AuthInterceptor,
  AuthModule,
  DefaultLocalStorageService
} from '@digiteq/angular-auth-oidc-client';


@NgModule({
  imports: [AuthModule.forRoot({
    config: {
      authority: environment.authentication.authority,
      redirectUrl: window.location.origin + '/login',
      postLogoutRedirectUri: window.location.origin,
      clientId: environment.authentication.clientId,
      scope: 'openid offline_access profile audience email', // 'openid profile offline_access ' + your scopes
      responseType: 'code',
      silentRenew: true,
      useRefreshToken: true,
      autoUserInfo: false,
      renewTimeBeforeTokenExpiresInSeconds: environment.authentication.renewTimeBeforeTokenExpiresInSeconds || 30,
      secureRoutes: [environment.apiUrl],
      customParamsAuthRequest: {
        audience: environment.authentication.audience,
      },
      disableIdTokenValidation: false,
      ignoreNonceAfterRefresh: true,
    }
  })],
  exports: [AuthModule],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
    {provide: AbstractSecurityStorage, useClass: DefaultLocalStorageService,},
  ]
})
export class AuthConfigModule {
}
